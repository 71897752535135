import React from 'react'

const Aboutme = () => {
  return (
    <div className="columns mr-2 ml-2 is-multiline" id="Aboutme">
      <div className="column is-three-fifths">
        <p className="bd-notification has-text-white is-size-3 has-text-weight-bold mb-3 is-size-5-mobile"><i className="far fa-grin-alt mr-2 has-text-white"></i>ABOUT ME</p>
        <div className="columns">
          <div className="column is-two-fifths-desktop is-half-mobile" id="kmdiv">
            <figure className="image is-1by1 ml-2" id="km">
              <img className="is-rounded" src="https://i.imgur.com/kuUmSNL.png" alt="Kumar Mehta"/>
            </figure>
          </div>
          <div className="column is-half">
            <h1 className="bd-notification is-info has-text-white is-size-6 has-text-weight-bold mb-3 has-text-left is-size-7-mobile"><i className="fas fa-user-tie mr-2 has-text-success"></i>SOFTWARE DEVELOPER | SOLICITOR | TRAVELER</h1>
            <p className="has-text-white is-size-6 has-text-left mr-3 mb-2 is-size-7-mobile is-italic">
            I am a former London based Solicitor who is transitioning their career into software engineering and has completed a Software Engineering bootcamp at General Assembly. I have been solidifying my understanding of computer science fundamentals and developing relevant skills for my first role in the tech industry. I am passionate about coding as there is more than one answer to a problem and really enjoy the logic and creative aspects of it. I am now looking for a junior full stack software developer role to combine my current skills and experience and grow these further.
            </p>
            <div className="has-text-left has-text-centered-mobile">
              <a href="mailto:kumarmehta019@gmail.com" rel="noreferrer" target="_blank"><i className="far fa-envelope fa-3x mr-4 mt-2 is-size-3-mobile" id="envelope"></i></a>
              <a href="https://github.com/Kumarmehta019" rel="noreferrer" target="_blank"><i className="fab fa-github fa-3x mr-4 has-text-white mt-2 is-size-3-mobile"></i></a>
              <a href="https://www.linkedin.com/in/kumarmehta019/" rel="noreferrer" target="_blank"><i className="fab fa-linkedin fa-3x mr-4 mt-2 is-size-3-mobile" id="linkedin"></i></a>
            </div>
          </div>
        </div>
      </div>
      <div className="column is-two-fifths mb-2">
        <p className="bd-notification has-text-white is-size-3 has-text-weight-bold mb-3 is-size-5-mobile"><i className="fas fa-tools mr-2 has-text-white"></i>SKILLS</p>
        <div className="columns is-multiline is-mobile">
          <div className="column is-one-fifth">
            <figure className="image is-48x48 ml-2 mb-4 has-text-white is-size-7 is-size-7-mobile ">
              <img src="https://i.imgur.com/QLAoilG.png" alt="HTML5"/> HTML5
            </figure>
          </div>
          <div className="column is-one-fifth">
            <figure className="image is-48x48 mb-4 has-text-white is-size-7 is-size-7-mobile">
              <img src="https://i.imgur.com/p9ijNkQ.png" alt="CSS3"/> CSS3
            </figure>
          </div>
          <div className="column is-one-fifth">
            <figure className="image is-48x48 mb-4 has-text-white is-size-7 is-size-7-mobile">
              <img src="https://i.imgur.com/06DXE6q.png" alt="Bulma"/> Bulma
            </figure>
          </div>
          <div className="column is-one-fifth">
            <figure className="image is-48x48 mb-4 has-text-white is-size-7 is-size-7-mobile">
              <img src="https://i.imgur.com/GQ22h5d.png" alt="Semantic UI"/>Semantic UI
            </figure>
          </div>
          <div className="column is-one-fifth">
            <figure className="image is-48x48 mb-4 has-text-white is-size-7 is-size-7-mobile">
              <img src="https://i.imgur.com/4J28xBj.png" alt="JavaScript"/> JavaScript
            </figure>
          </div>
          <div className="column is-one-fifth">
            <figure className="image is-48x48 ml-2 mb-4 has-text-white is-size-7 is-size-7-mobile">
              <img src="https://i.imgur.com/269QFt8.png" alt="Express"/> Express
            </figure>
          </div>
          <div className="column is-one-fifth">
            <figure className="image is-48x48 mb-4 has-text-white is-size-7 is-size-7-mobile">
              <img src="https://i.imgur.com/oaLtnyr.png" alt="Django"/> Django
            </figure>
          </div>
          <div className="column is-one-fifth">
            <figure className="image is-48x48 mb-4 has-text-white is-size-7 is-size-7-mobile">
              <img src="https://i.imgur.com/thcnUp5.png" alt="Node"/> Node
            </figure>
          </div>
          <div className="column is-one-fifth">
            <figure className="image is-48x48 mb-4 has-text-white is-size-7 is-size-7-mobile">
              <img src="https://i.imgur.com/jcHZFNn.png" alt="MongoDB"/> MongoDB
            </figure>
          </div>
          <div className="column is-one-fifth">
            <figure className="image is-48x48 mb-4 has-text-white is-size-7 is-size-7-mobile">
              <img src="https://i.imgur.com/xhyb7hf.png" alt="React"/> React
            </figure>
          </div>
          <div className="column is-one-fifth">
            <figure className="image is-48x48 ml-2 mb-4 has-text-white is-size-7 is-size-7-mobile">
              <img src="https://i.imgur.com/yBY3v0G.png" alt="Python"/> Python
            </figure>
          </div>
          <div className="column is-one-fifth">
            <figure className="image is-48x48 mb-4 has-text-white is-size-7 is-size-7-mobile">
              <img src="https://i.imgur.com/uy64LX3.png" alt="PostgreSQL"/> PostgreSQL
            </figure>
          </div>
          <div className="column is-one-fifth">
            <figure className="image is-48x48 mb-4 has-text-white is-size-7 is-size-7-mobile">
              <img src="https://i.imgur.com/RRG6l2x.png" alt="NPM"/> NPM
            </figure>
          </div>
          <div className="column is-one-fifth">
            <figure className="image is-48x48 mb-4 has-text-white is-size-7 is-size-7-mobile">
              <img src="https://i.imgur.com/Q3J9WQ4.png" alt="Yarn"/> Yarn
            </figure>
          </div>
          <div className="column is-one-fifth">
            <figure className="image is-48x48 mb-4 has-text-white is-size-7 is-size-7-mobile">
              <img src="https://i.imgur.com/7mM7Wbi.png" alt="VS Code"/> VS Code
            </figure>
          </div>
          <div className="column is-one-fifth">
            <figure className="image is-48x48 ml-2 mb-4 has-text-white is-size-7 is-size-7-mobile">
              <img src="https://i.imgur.com/EXC55aP.png" alt="Git"/> Git
            </figure>
          </div>
          <div className="column is-one-fifth">
            <figure className="image is-48x48 mb-4 has-text-white is-size-7 is-size-7-mobile">
              <img src="https://i.imgur.com/e6hTp7r.png" alt="GitHub"/> GitHub
            </figure>
          </div>
          <div className="column is-one-fifth">
            <figure className="image is-48x48 mb-4 has-text-white is-size-7 is-size-7-mobile">
              <img src="https://i.imgur.com/BUu7uGm.png" alt="Heroku"/> Heroku
            </figure>
          </div>
          <div className="column is-one-fifth">
            <figure className="image is-48x48 mb-4 has-text-white is-size-7 is-size-7-mobile">
              <img src="https://i.imgur.com/oZuCLEo.png" alt="Figma"/> Figma
            </figure>
          </div>
          <div className="column is-one-fifth">
            <figure className="image is-48x48 mb-4 has-text-white is-size-7 is-size-7-mobile">
              <img src="https://i.imgur.com/eBRdtZ9.png" alt="Canva"/> Canva
            </figure>
          </div>
          <div className="column is-one-fifth">
            <figure className="image is-48x48 ml-2 mb-4 has-text-white is-size-7 is-size-7-mobile">
              <img src="https://i.imgur.com/QQcd5oZ.png" alt="Slack"/> Slack
            </figure>
          </div>
          <div className="column is-one-fifth">
            <figure className="image is-48x48 mb-4 has-text-white is-size-7 is-size-7-mobile">
              <img src="https://i.imgur.com/o3mC4Rw.png" alt="Trello"/> Trello
            </figure>
          </div>
          <div className="column is-one-fifth">
            <figure className="image is-48x48 mb-4 has-text-white is-size-7 is-size-7-mobile">
              <img src="https://i.imgur.com/KqnssUZ.png" alt="Asana"/> Asana
            </figure>
          </div>
          <div className="column is-one-fifth">
            <figure className="image is-48x48 mb-4 has-text-white is-size-7 is-size-7-mobile">
              <img src="https://i.imgur.com/Nmo68a4.png" alt="Zoom"/> Zoom
            </figure>
          </div>
          <div className="column is-one-fifth">
            <figure className="image is-48x48 mb-4 has-text-white is-size-7 is-size-7-mobile">
              <img src="https://i.imgur.com/Y0hKfq3.png" alt="Netlify"/> Netlify
            </figure>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Aboutme
