import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import Navbar from './components/Navbar'
import Footer from './components/Footer'
import Home from './components/Home'


const App = () => {

  return (
    <BrowserRouter>
      <Navbar />
      <Home/>
      <Footer />
    </BrowserRouter>
  )
}

export default App