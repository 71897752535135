import React from 'react'

const Footer = () => {
  return (
    <div className="footer p-1" id="Contactinfo">
      <div className="container">
        <div className="col has-text-centered">
          <img className="pr-3 mt-5" src="https://i.imgur.com/TAyaGfW.gif" alt="KM logo" width="100px" />
        </div>
        <div className="col">
          <h4 className="is-size-3 is-size-5-mobile has-text-centered has-text-weight-bold has-text-white mb-3"><i className="far fa-address-card mr-2 has-text-white"></i>Contact Info</h4>
        </div>
        <div className="row mt-2">
          <div className="col">
            <a href="mailto:kumarmehta019@gmail.com" rel="noreferrer" target="_blank"><i className="far fa-envelope fa-3x mr-5" id="envelope"></i></a>
          </div>
          <div className="col">
            <a href="https://github.com/Kumarmehta019" rel="noreferrer" target="_blank"><i className="fab fa-github fa-3x mr-5 has-text-white"></i></a>
          </div>
          <div className="col mb-2">
            <a href="https://www.linkedin.com/in/kumarmehta019/" rel="noreferrer" target="_blank"><i className="fab fa-linkedin fa-3x mr-5" id="linkedin"></i></a>
          </div>
        </div>
        <hr className="has-background-white mt-2 mb-2" />
        <div className="row">
          <p className="col-sm has-text-white is-size-7 has-text-centered mb-1">
            &copy;2021-{new Date().getFullYear()} Kumar Mehta <br/>
            Full Stack Software Developer
          </p>
        </div>
      </div>
    </div>
  )
}

export default Footer
