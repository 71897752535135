import React from 'react'

const Projects = () => {
  return (
    <div className="columns mr-2 ml-2 is-multiline">
      <div className="column is-half has-text-centered">
        <a className="bd-notification is-size-4 has-text-weight-bold is-size-5-mobile has-text-centered" id="links" href="https://kumarmehta019.github.io/Space-Invaders/" rel="noreferrer" target="_blank">Project 1: Space Invaders</a>
        <div className="columns is-mobile">
          <div className="column">
            <figure className="image is-5by3 mt-1 is-one-third-desktop">
              <img src="https://i.imgur.com/3qXunXo.gif" alt="Space Invaders"/>
            </figure>
          </div>
        </div>
      </div>
      <div className="column is-half mb-2 has-text-centered">
        <a className="bd-notification is-size-4 has-text-weight-bold mb-3 is-size-5-mobile has-text-centered" id="links" href="https://github.com/Kumarmehta019/Space-Invaders" rel="noreferrer" target="_blank">Project Info</a>
        <div className="columns is-multiline is-mobile">
          <div className="column">
            <div className="content has-text-black">
              <h2 className="has-text-link is-size-6 is-size-6-touch has-text-centered mb-0">Technologies Used:</h2>
              <ul className="mb-1 mt-0 has-text-weight-bold is-size-7-touch">
                <li className="lists">JavaScript</li>
                <li className="lists">HTML5</li>
                <li className="lists">CSS3</li>
                <li className="lists">GitHub</li>
                <li className="lists">Figma</li>
                <li className="lists">Git</li>
                <li className="lists">Animate.css</li>
              </ul>
              <p className="has-text-black is-size-6 has-text-justified mr-3 is-size-7-touch ">
              Space Invaders is a classic arcade game from the 80s. The player aims to shoot laser beams at the invading alien armada, before it reaches the planet&#39;s surface using a mounted gun turret. The player can only move left or right. The aliens also move from left to right, and also move down each time they reach the side of the screen. The aliens also periodically drop bombs towards the player.<br/><br/>
              I wanted to create a similar game but sought to modernise it by using better images for the spaceship, aliens and laser beams. The aim was to create the nostalgic feel for the game but with better graphics and music. 
              </p>
              <a className="bd-notification is-size-6 has-text-weight-bold is-size-7-mobile has-text-centered mr-4" id="projectlink" href="https://kumarmehta019.github.io/Space-Invaders/" rel="noreferrer" target="_blank"><i className="fas fa-link mr-1"></i> Project</a>
              <a className="bd-notification is-size-6 has-text-weight-bold is-size-7-mobile has-text-centered mr-4" id="githublink" href="https://github.com/Kumarmehta019/Space-Invaders" rel="noreferrer" target="_blank"><i className="fab fa-github mr-2"></i>GitHub</a>
            </div>
          </div>
        </div>
      </div>
      <div className="column is-half has-text-centered">
        <a className="bd-notification is-size-4 has-text-weight-bold is-size-5-mobile has-text-centered" id="links" href="https://isaac-kumar-yugioh.netlify.app/" rel="noreferrer" target="_blank">Project 2: Yu-Gi-Oh</a>
        <div className="columns is-mobile">
          <div className="column">
            <figure className="image is-5by3 mt-1 is-one-third-desktop">
              <img src="https://user-images.githubusercontent.com/88886169/146043756-d5f8dc86-e23a-4421-ae6c-d7d6099bf3de.png" alt="Yu-Gi-Oh"/>
            </figure>
          </div>
        </div>
      </div>
      <div className="column is-half mb-2 has-text-centered">
        <a className="bd-notification is-size-4 has-text-weight-bold mb-3 is-size-5-mobile has-text-centered" id="links" href="https://github.com/Kumarmehta019/Yu-Gi-Oh" rel="noreferrer" target="_blank">Project Info</a>
        <div className="columns is-multiline is-mobile">
          <div className="column">
            <div className="content has-text-black">
              <h2 className="has-text-link is-size-6 is-size-6-touch has-text-centered mb-0">Technologies Used:</h2>
              <ul className="mb-1 mt-0 has-text-weight-bold is-size-7-touch">
                <li className="lists">JavaScript</li>
                <li className="lists">HTML5</li>
                <li className="lists">CSS3</li>
                <li className="lists">React</li>
                <li className="lists">GitHub</li>
                <li className="lists">Figma</li>
                <li className="lists">Bulma</li>
                <li className="lists">Axios</li>
                <li className="lists">Git</li>
                <li className="lists">Insomnia</li>
                <li className="lists">Yarn</li>
                <li className="lists">Animate.css</li>
                <li className="lists">Zoom</li>
                <li className="lists">Slack</li>
                <li className="lists">Netlify</li>
              </ul>
              <p className="has-text-black is-size-6 has-text-justified mr-3 is-size-7-touch">
              This was a pair coding project, where we were briefed to build a React application that consumes a public API. My coding partner and I both had to decide on a common theme for the project. We both made a list of interests we had and from that we both had a common interest in Yu-Gi-Oh and decided to use the Yu-Gi-Oh API to build a website. 
              </p>
              <a className="bd-notification is-size-6 has-text-weight-bold is-size-7-mobile has-text-centered mr-4" id="projectlink" href="https://isaac-kumar-yugioh.netlify.app/" rel="noreferrer" target="_blank"><i className="fas fa-link mr-1"></i> Project</a>
              <a className="bd-notification is-size-6 has-text-weight-bold is-size-7-mobile has-text-centered mr-4" id="githublink" href="https://github.com/Kumarmehta019/Yu-Gi-Oh" rel="noreferrer" target="_blank"><i className="fab fa-github mr-2"></i>GitHub</a>
            </div>
          </div>
        </div>
      </div>
      <div className="column is-half has-text-centered">
        <a className="bd-notification is-size-4 has-text-weight-bold is-size-5-mobile has-text-centered" id="links" href="https://museummapperkm.herokuapp.com/" rel="noreferrer" target="_blank">Project 3: Museum Mapper</a>
        <div className="columns is-mobile">
          <div className="column">
            <figure className="image is-5by3 mt-1 is-one-third-desktop">
              <img src="https://i.imgur.com/4Bs6g2b.gif" alt="Museum Mapper"/>
            </figure>
          </div>
        </div>
      </div>
      <div className="column is-half mb-2 has-text-centered">
        <a className="bd-notification is-size-4 has-text-weight-bold mb-3 is-size-5-mobile has-text-centered" id="links" href="https://github.com/Kumarmehta019/Museum-Mapper" rel="noreferrer" target="_blank">Project Info</a>
        <div className="columns is-multiline is-mobile">
          <div className="column">
            <div className="content has-text-black">
              <h2 className="has-text-link is-size-6 is-size-6-touch has-text-centered mb-0">Technologies Used:</h2>
              <ul className="mb-1 mt-0 has-text-weight-bold is-size-7-touch">
                <li className="lists">JavaScript</li>
                <li className="lists">HTML5</li>
                <li className="lists">CSS3</li>
                <li className="lists">React</li>
                <li className="lists">GitHub</li>
                <li className="lists">Figma</li>
                <li className="lists">Bulma</li>
                <li className="lists">Axios</li>
                <li className="lists">Git</li>
                <li className="lists">Insomnia</li>
                <li className="lists">Yarn</li>
                <li className="lists">Animate.css</li>
                <li className="lists">MongoDB</li>
                <li className="lists">Node</li>
                <li className="lists">Express</li>
                <li className="lists">Asana</li>
                <li className="lists">Zoom</li>
                <li className="lists">Slack</li>
                <li className="lists">FontAwesome</li>
                <li className="lists">Swiperjs</li>
                <li className="lists">Heroku</li>
                <li className="lists">NPM</li>
              </ul>
              <p className="has-text-black is-size-6 has-text-justified mr-3 is-size-7-touch">
              As a group of four, we were briefed to build a full-stack application. As a group we had to decide on a common theme for the project. We all made a list of interests we had and from that we all had a common interest in museums and decided to build a website dedicated to information about natural history museums in England. 
              </p>
              <a className="bd-notification is-size-6 has-text-weight-bold is-size-7-mobile has-text-centered mr-4" id="projectlink" href="https://museummapperkm.herokuapp.com/" rel="noreferrer" target="_blank"><i className="fas fa-link mr-1"></i> Project</a>
              <a className="bd-notification is-size-6 has-text-weight-bold is-size-7-mobile has-text-centered mr-4" id="githublink" href="https://github.com/Kumarmehta019/Museum-Mapper" rel="noreferrer" target="_blank"><i className="fab fa-github mr-2"></i>GitHub</a>
            </div>
          </div>
        </div>
      </div>
      <div className="column is-half has-text-centered">
        <a className="bd-notification is-size-4 has-text-weight-bold is-size-5-mobile has-text-centered" id="links" href="https://tkgfashion.herokuapp.com/" rel="noreferrer" target="_blank">Project 4: TKG Fashion</a>
        <div className="columns is-mobile">
          <div className="column">
            <figure className="image is-5by3 mt-1 is-one-third-desktop">
              <img src="https://i.imgur.com/7uhuhwY.png" alt="TKG Fashion"/>
            </figure>
          </div>
        </div>
      </div>
      <div className="column is-half mb-2 has-text-centered">
        <a className="bd-notification is-size-4 has-text-weight-bold mb-3 is-size-5-mobile has-text-centered" id="links" href="https://github.com/Kumarmehta019/TKG-Fashion" rel="noreferrer" target="_blank">Project Info</a>
        <div className="columns is-multiline is-mobile">
          <div className="column">
            <div className="content has-text-black">
              <h2 className="has-text-link is-size-6 is-size-6-touch has-text-centered mb-0">Technologies Used:</h2>
              <ul className="mb-1 mt-0 has-text-weight-bold is-size-7-touch">
                <li className="lists">JavaScript</li>
                <li className="lists">HTML5</li>
                <li className="lists">CSS3</li>
                <li className="lists">React</li>
                <li className="lists">GitHub</li>
                <li className="lists">Google Jamboard</li>
                <li className="lists">Semantic UI</li>
                <li className="lists">Axios</li>
                <li className="lists">Git</li>
                <li className="lists">Insomnia</li>
                <li className="lists">Yarn</li>
                <li className="lists">Animate.css</li>
                <li className="lists">PostgreSQL</li>
                <li className="lists">Django</li>
                <li className="lists">TablePlus</li>
                <li className="lists">Asana</li>
                <li className="lists">Zoom</li>
                <li className="lists">Slack</li>
                <li className="lists">FontAwesome</li>
                <li className="lists">Swiperjs</li>
                <li className="lists">Heroku</li>
                <li className="lists">Pure React Carousel</li>
                <li className="lists">Cloudinary</li>
                <li className="lists">LucidChart</li>
                <li className="lists">Python</li>
                <li className="lists">Canva</li>
                <li className="lists">Imgur</li>
              </ul>
              <p className="has-text-black is-size-6 has-text-justified mr-3 is-size-7-touch">
              As a group of three we discussed various project options and eventually decided to build an e-commerce App. I was particularly keen on the idea as I had not created an e-commerce App before and was excited at the prospect of building my first e-commerce App. TKG Fashion focuses on user&#39;s browsing and purchasing items of clothing. ASOS, Boohoo, Next and Shein influenced this project, a special thanks goes out to them.
              </p>
              <a className="bd-notification is-size-6 has-text-weight-bold is-size-7-mobile has-text-centered mr-4" id="projectlink" href="https://tkgfashion.herokuapp.com/"rel="noreferrer" target="_blank"><i className="fas fa-link mr-1"></i> Project</a>
              <a className="bd-notification is-size-6 has-text-weight-bold is-size-7-mobile has-text-centered mr-4" id="githublink" href="https://github.com/Kumarmehta019/TKG-Fashion" rel="noreferrer" target="_blank"><i className="fab fa-github mr-2"></i>GitHub</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Projects
