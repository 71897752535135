import React from 'react'

const Interests = () => {
  return (
    <div className="ml-2 mr-2">
      <ul className="mt-0 has-text-weight-bold has-text-white is-size-7-touch has-text-centered">
        <li className="lists1 is-size-5 is-size-6-touch mb-2 mt-2 is-underlined"><i className="fas fa-plane mr-2"></i>Travel</li>
        <p className="is-size-6 is-size-7-touch has-text-weight-light has-text-centered">Travelling to experience different cultures, recently visited: Dubai, Switzerland and Italy. I&#39;m hoping to visit Australia and New Zealand next.</p>
        <li className="lists1 is-size-5 is-size-6-touch mb-2 mt-2 is-underlined"><i className="fas fa-cookie-bite mr-2"></i>Cook</li>
        <p className="is-size-6 is-size-7-touch has-text-weight-light has-text-centered">I enjoy cooking and recently made stone baked pizzas and penne arrabiata. I want to learn baking next, perhaps a victoria sponge or cupcakes.</p>
        <li className="lists1 is-size-5 is-size-6-touch mb-2 mt-2 is-underlined"><i className="fas fa-music mr-2"></i>Music</li>
        <p className="is-size-6 is-size-7-touch has-text-weight-light has-text-centered">I love Spotify and frequently enjoy listening to new playlists. Music helps me focus when working on coding projects.</p>
        <li className="lists1 is-size-5 is-size-6-touch mb-2 mt-2 is-underlined"><i className="fas fa-film mr-2"></i>Films</li>
        <p className="is-size-6 is-size-7-touch has-text-weight-light has-text-centered">I really enjoy watching movies of all genres, I&#39;m really into the Marvel Cinematic Universe and recently watched Spiderman: No Way Home, which was amazing!</p><br/>
      </ul>
    </div>
  )
}

export default Interests
