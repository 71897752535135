import React from 'react'
import { WindupChildren, Pace } from 'windups'
import Aboutme from './Aboutme'
import Projects from './Projects'
// import Experience from './Experience'
import Interests from './Interests'

const Home = () => {
  return (
    <section className="" id='home'>
      <section className="hero is-small is-primary has-text-centered">
        <div className="hero-body">
          <WindupChildren>
            <p className="title mb-0">
              <Pace ms={100}> <h1 className="name is-size-2 is-size-4-mobile">{'KUMAR MEHTA'}</h1></Pace>
            </p>
            <br />
            <p className="subtitle">
              <Pace ms={100}> <h2 className="jobtitle is-size-3 is-size-5-mobile">{'FULL STACK SOFTWARE DEVELOPER'}</h2></Pace>
            </p>
            <br />
            <Pace ms={100}><i className="fas fa-angle-double-down has-text-black is-size-2 animate__animated animate__bounce animate__slow animate__infinite is-size-5-mobile"></i></Pace>
          </WindupChildren>
        </div>
      </section>
      <section className="has-background-grey-dark has-text-centered">
        <br/>
        <Aboutme />
        <br/>
      </section>
      <section className="has-background-primary">
        <br/>
        <div className="has-text-centered has-text-black has-text-weight-bold is-size-3 is-size-5-mobile mb-3" id="Projects"><i className="fas fa-laptop-code has-text-black mr-2"></i>PROJECTS</div>
        <Projects />
        <br/>
      </section>
      {/* <section className="has-background-grey-dark has-text-centered mb-3">
        <br/>
        <div className="has-text-centered has-text-white has-text-weight-bold is-size-3 is-size-5-mobile mb-3"><i className="fas fa-clipboard-list has-text-white mr-2"></i>Experience</div>
        <Experience />
      </section> */}
      <section className="has-background-grey-dark">
        <br/>
        <div className="has-text-centered has-text-white has-text-weight-bold is-size-3 is-size-5-mobile mb-3" id="Interests"><i className="fas fa-thumbs-up has-text-white mr-2"></i>INTERESTS</div>
        <Interests />
      </section>
    </section>
  )
}

export default Home
