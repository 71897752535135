import React from 'react'


const Navbar = () => {

  return (
    <nav className="navbar is-black" id="navbar">
      <div className="container navbar-items">
        <div className="navbar-brand">
          <span role="img" aria-label="logo" className="title">
            <a href="#home" className="link">
              <img className="mmlogo" src="https://i.imgur.com/TAyaGfW.gif" alt="KM logo" width="130px"/>
            </a>
          </span>
        </div>
        <div className="navbar-start">
          
        </div>
        <div className="navbar-end">
          <div className="navbar-item">
            <a href="#Aboutme" className="link is-size-5 has-text-weight-bold is-size-6-mobile"><i className="far fa-grin-alt has-text-warning is-hidden-mobile"></i> About Me</a>
          </div>
          <div className="navbar-item">
            <a href="#Projects" className="link is-size-5 has-text-weight-bold is-size-6-mobile"><i className="fas fa-laptop-code has-text-link-dark is-hidden-mobile"></i> Projects</a>
          </div>
          {/* <div className="navbar-item">
            <a href="#Experience" className="link is-size-5 has-text-weight-bold is-size-6-mobile"><i className="fas fa-clipboard-list  has-text-danger-dark is-hidden-mobile"></i> Experience</a>
          </div> */}
          <div className="navbar-item">
            <a href="#Interests" className="link is-size-5 has-text-weight-bold is-size-6-mobile"><i className="fas fa-thumbs-up has-text-danger-dark is-hidden-mobile"></i> Interests</a>
          </div>
          <div className="navbar-item">
            <a href="#Contactinfo" className="link is-size-5 has-text-weight-bold is-size-6-mobile"><i className="far fa-address-card has-text-success-dark is-hidden-mobile"></i> Contact Info</a>
          </div>
        </div>
      </div>
    </nav>
  )
}

export default Navbar
